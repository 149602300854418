<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n';
import dayjs from 'dayjs';
import { computed } from '@vue/reactivity';
const $t = i18n.global.t;

const appStore = useAppStore();
const $route = useRoute();
const $router = useRouter();

function coming() {
  // $router.push({ name: "ranking" });
  ElMessage.error($t('msg.64'));
}

function jumpGame() {
  window.open('https://interstellar-rangeland.com/');
}
function tomarket() {
  $router.push({ name: 'nftMarket' });

  // const endTimeStamp = new Date(Date.UTC(2022, 5, 28, 12, 0, 0)).getTime();
  // if (!dayjs().isBefore(dayjs(endTimeStamp))) {
  //   $router.push({ name: 'nftMarket' });
  // } else {
  //   ElMessage({
  //     type: 'info',
  //     message: $t('msg.38')
  //   });
  // }
}

const pcImg = computed(() => {
  return require(`@img/rangeland/${appStore.curLang}.png`);
});
</script>

<template>
  <img class="bgc" src="@img/rangeland/banner.jpg" v-if="appStore.curDevice !== 'phone'" />
  <div :class="['rangeland-wrap', appStore.curLang]" v-if="appStore.curDevice !== 'phone'">
    <img alt="" :src="pcImg" :class="['rangeland-text', appStore.curLang]" />
    <div :class="['main', appStore.curLang]">
      <a @click="jumpGame">{{ $t('rangeland.1') }}</a>
    </div>
    <div :class="['btn-group', appStore.curLang]">
      <a @click="tomarket">{{ $t('rangeland.2') }}</a>
      <a href="#" target="_blank">{{ $t('rangeland.3') }}</a>
      <a @click="coming">{{ $t('rangeland.4') }}</a>
    </div>
  </div>

  <div :class="['rangeland-phone-wrap', appStore.curLang]" v-else>
    <img class="text" v-if="appStore.curLang === 'cn'" src="@img/rangeland/cn.png" />
    <img
      class="text"
      v-if="appStore.curLang === 'en'"
      style="width: 335px"
      src="@img/rangeland/en.png"
    />
    <img class="text" v-if="appStore.curLang === 'kn'" src="@img/rangeland/kn.png" />
    <div :class="['main', appStore.curLang]">
      <a @click="jumpGame">{{ $t('rangeland.1') }}</a>
    </div>
    <div :class="['btn-group', appStore.curLang]">
      <a @click="tomarket">{{ $t('rangeland.2') }}</a>
      <a href="#" target="_blank">{{ $t('rangeland.3') }}</a>
      <a @click="coming">{{ $t('rangeland.4') }}</a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@font-face {
  font-family: 'rangeland-cn';
  src: url('/assets/font/rangeland-cn.ttf');
}
@font-face {
  font-family: 'rangeland-kn';
  src: url('/assets/font/rangeland-kn.ttf');
}
@font-face {
  font-family: 'rangeland-en';
  src: url('/assets/font/rangeland-en.off');
}

.rangeland-wrap {
  position: relative;
  overflow: auto;
  z-index: 9;
  height: 100vh;
  /* height: calc(100vh - $pcTopBarHeight);
  @media (max-width: $phone) {
    height: calc(100vh - $mobTopBarHeight);
  } */

  a {
    color: #ffffff;
    font-weight: bold;
  }
  &.cn a {
    font-family: 'rangeland-cn';
  }
  &.kn a {
    font-family: 'rangeland-kn';
  }
  &.en a {
    font-family: 'rangeland-en';
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 11.39%;
    transform: translateX(-50%);
    width: 484px;
    height: 229px;
    background: url('~@img/rangeland/h5/logo.png') no-repeat;
    background-size: cover;
  }

  .rangeland-text {
    position: absolute;
    top: 35.19%;
    left: 50%;
    transform: translateX(-50%);

    &.cn {
      width: 4rem;
    }

    &.en {
      width: 6rem;
    }

    &.kn {
      width: 5.2rem;
    }
  }

  .main {
    position: absolute;
    z-index: 999;
    top: 52.5%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 3.62rem;
    height: 1.17rem;
    text-align: center;
    line-height: 1.17rem;
    font-weight: Bold;
    font-size: 0.37rem;
    background: url('~@img/rangeland/primary.png') no-repeat;

    &.cn {
      top: 58%;
    }
  }
  .btn-group {
    position: absolute;
    top: 69.17%;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    a {
      margin-right: 0.28rem;
      width: 3.47rem;
      height: 0.87rem;
      text-align: center;
      line-height: 0.87rem;
      font-size: 28px;
      background: url('~@img/rangeland/default.png') no-repeat;
    }
    a:last-child {
      margin-right: 0;
    }

    &.cn {
      top: 74%;
    }
  }
}

.rangeland-phone-wrap {
  position: relative;
  height: calc(100vh - 1.08rem);
  overflow: auto;
  background: url('~@img/rangeland/h5/banner.png') no-repeat;
  background-size: cover;
  a {
    font-weight: bold;
  }
  &::after {
    content: '';
    position: absolute;
    top: 1.36rem;
    left: 50%;
    transform: translate(-50%);
    display: block;
    width: 4.7rem;
    height: 2.24rem;
    background: url('~@img/rangeland/h5/logo.png') no-repeat;
    background-size: cover;
  }
  .text {
    width: 80%;
    position: absolute;
    top: 3.96rem;
    left: 50%;
    transform: translate(-50%);
    display: block;
  }
  .main {
    background: url('~@img/rangeland/h5/primary.png') no-repeat;
    background-size: cover;
    width: 4.92rem;
    height: 1.6rem;
    text-align: center;
    line-height: 1.6rem;
    position: absolute;
    top: 6.04rem;
    left: 50%;
    transform: translate(-50%, 0);
    a {
      font-size: 0.46rem;
      color: #fff;
    }

    &.cn {
      top: 6.8rem;
    }
  }
  .btn-group {
    position: absolute;
    top: 8.04rem;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    a {
      margin-bottom: 0.28rem;
      width: 3.2rem;
      height: 0.8rem;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.35rem;
      background: url('~@img/rangeland/h5/default.png') no-repeat;
      background-size: 100%;
      color: #fff;
    }
    a:last-child {
      margin-right: 0;
    }

    &.cn {
      top: 8.9rem;
    }
  }
}

.bgc {
  width: 100%;
  /* min-width: 1920px; */
  object-fit: cover;
  @include -width(100%, 1920px, 100%);
  position: fixed;
  left: 0;
  top: 0;
}
</style>
